import React from "react";
import Navbar from "react-bootstrap/Navbar";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import "../styles/PageLayout.css"
export const PageLayout = (props: any) => {
    
    return (
        <>
            <Navbar className="navBar" style={{ background: "#29364a" }} variant="dark">
                <h5><a className="navbar-brand" href="/">PeopleNet A/S</a></h5>
                <div className="logon">
                    <AccountCircleIcon/>
                    <h5 className="accountName">{props.accountName}</h5>
                </div>

            </Navbar>
            {props.children}
        </>
    );
};
