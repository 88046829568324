import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { Button } from "react-bootstrap";
import { ICourseRegistration } from "./RegistrationsTable";
import { updatePeoplenetRegistrationStatus, updateRegistrationStatus } from "../api";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import "../styles/SbhDialog.css";
import { blueGrey } from "@mui/material/colors";

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}



export function SagsbehandlerDialog(props: { open: boolean; onClose: () => void; registration: ICourseRegistration | undefined; token: string; user: any; }): JSX.Element {
  const [status, setStatus] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleUpdateRegistration = () => {
    if (props.registration) {

      const updatedReg = props.registration;
      updatedReg.seStatus = status;
      const pplnetPostData = {
        "id":props.registration.seid,
        "manager": "",
        "status": status,
      }

      if (props.user) {
        const split = props.user.split(" ");
        if (split.length > 2) {
          pplnetPostData.manager = split[0].at(0) + split[1].at(0) + split[2].at(0);
          updatedReg.seBehandler = split[0].at(0) + split[1].at(0) + split[2].at(0);
        } else {
          pplnetPostData.manager = split[0].at(0) + split[1].at(0);
          updatedReg.seBehandler = split[0].at(0) + split[1].at(0);
        }

        if (props.registration.firma.toLowerCase() == "4D".toLowerCase()) {

          const data = {
              "seid": props.registration.seid,
              "seSubject": props.registration.seSubject,
              "seDateTime": props.registration.seDateTime,
              "seBody": props.registration.seBody,
              "seBehandler": updatedReg.seBehandler,
              "seStatus": updatedReg.seStatus,
          }
          updateRegistrationStatus(props.token, data);
        } else if (props.registration.firma.toLowerCase() == "PeopleNet".toLocaleLowerCase()) {
          updatePeoplenetRegistrationStatus(pplnetPostData);        
        }

        props.onClose();
      } else {
        setError("Der var et problem med at finde sagsbehandler. Kontakt ndh@peoplenet.dk")
      }

    }

  }
  const handeSetStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value)
  }

  return (
    <Dialog onClose={props.onClose} open={props.open} >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Sagsbehandling</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: blueGrey,
        }}
      >
        <CloseIcon />
      </IconButton>
      {error && (
        <div>
          {error}
        </div>
      )}
      <DialogContent className='dialogContainer'>
        <FormControl fullWidth>

          <Select
            displayEmpty
            value={status}
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={handeSetStatus}
          >
            <MenuItem value="" disabled selected>Vælg status...</MenuItem>
            <MenuItem value="I gang">I gang</MenuItem>
            <MenuItem value="Behandlet">Behandlet</MenuItem>

          </Select>


        </FormControl>

      </DialogContent>



      <DialogActions>

        <Button onClick={props.onClose} color="error">
          Afbryd og luk
        </Button>
        <Button onClick={() => handleUpdateRegistration()} color="success">
          Gem og luk
        </Button>


      </DialogActions>
    </Dialog>
  )
}