import { DefaultButton, Link } from "@fluentui/react";
import { ICourseType } from "./DataTable";
import  {useState} from 'react';
import '../styles/CourseActionList.css'



export default function CourseActionList(props: { courseTypes: ICourseType[], 
    onClickDate: React.Dispatch<React.SetStateAction<ICourseType | null>>;
    onClickPrice: React.Dispatch<React.SetStateAction<ICourseType | null>>;
    onClickDuration: React.Dispatch<React.SetStateAction<ICourseType | null>>;}){


    return (
        <div className="courseActionContainer">
            {props.courseTypes.map((type) => {

                return(
                    <div className="courseContainer">
                        <div className="courseTitle">{type.kursusTitel}</div>
                        <div className="btnContainer"> 
                            <DefaultButton  className="btnStyle" onClick={() => props.onClickDate(type)}>Administrer datoer</DefaultButton>
                            <DefaultButton className="btnStyle"  onClick={() => props.onClickPrice(type)}>Administrer priser</DefaultButton>
                            <DefaultButton className="btnStyle"  onClick={() => props.onClickDuration(type)}>Administrer varighed</DefaultButton>
                        </div>
                    </div>

                )
            })}

        </div>
    )
}