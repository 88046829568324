import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, Selection } from '@fluentui/react/lib/DetailsList';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { getCourseTypes, getCourseDates, getCourseDays, addOrUpdateCourseDate, addOrUpdateCourseDay, deleteCourseDate, getPrograms, updateCoursePrice } from "../api";
import { Checkbox, Spinner, CommandBar, DatePicker, DayOfWeek, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, ICommandBarItemProps, IDropdownOption, Link, Panel, PanelType, PrimaryButton, defaultDatePickerStrings, initializeIcons, Label, Modal, makeStyles, mergeStyles } from '@fluentui/react';
import moment from 'moment';
import "moment-timezone";
import { AxiosResponse } from 'axios';
import CourseDetailList from './CourseDetailList';
import CourseActionList from './CourseActionList';
import PricePanel from './PricePanel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { useBoolean } from '@fluentui/react-hooks';
import DurationPanel from './DurationPanel';


const styleAccordion = mergeStyles({
  margin: '5px',
});
const styleTypography = mergeStyles({
  minWidth: '900px',
});

initializeIcons();

export interface ICourseType {
  kursusTitel: string;
  kursustypeID: number;
  program: string;
  kursusID: number;
  pris: number;
  varighed: number;

}

export interface ICourseTypePrice {
  kursusTitel: string;
  kursustypeID: number;
  pris: number;
  programnavnID: number;
  varighed: number;
}

export interface ICourse {
  kursusID: number;
  kursustypeID: number;
  kursustype?: string;
  kursusTitel?: string;
  startDato: Date;
  startDato1Tid?: string;
  startDatoTid?: string;
  slutDato?: Date;
  kursusAdresse?: number;
  program?: string;
  version?: string;
  miljo?: string;
  sprog?: string;
  niveau?: string;
  niveauNr?: number;
  antalDeltagere?: number;
  kursusLokale?: number;
}

export interface ICourseDay {
  kursusdagID: number;
  kursusID: number;
  startDato?: Date;
  slutDato?: Date;
}

export interface ICourseProgram {
  programnavnID: number;
  navn: string;
}

export function DataTable() {

  const [programColumns, setProgramColumns] = useState<IColumn[]>([
    {
      name: "Program", key: "navn", minWidth: 100, fieldName: "navn", onRender: (item) => {
        return <Link onClick={() => setProgram(item)}>{item.navn}</Link>
      }
    }
  ]);

  const [programs, setPrograms] = useState<ICourseProgram[]>([]);
  const [filteredPrograms, setFilteredPrograms] = useState<ICourseProgram[]>([]);
  const [program, setProgram] = useState<ICourseProgram | null>(null);

  const [courseTypes, setCourseTypes] = useState<ICourseType[]>([]);
  const [courseType, setCourseType] = useState<ICourseType | null>(null);
  const [courseTypePrice, setCourseTypePrice] = useState<ICourseType | null>(null);
  const [courseTypeDuration, setCourseTypeDuration] = useState<ICourseType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { instance, accounts } = useMsal();

  useEffect(() => {
    setIsLoading(true);
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then((response) => {
      getPrograms(response.accessToken).then(({ data }: any) => {
        setPrograms(data);
        setFilteredPrograms(data);
        setIsLoading(false);
      })
    });
  }, []);

  useEffect(() => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then((response) => {
      if (program) {
        getCourseTypes(response.accessToken, program?.programnavnID).then((data: any) => {
          setCourseTypes(data.data);
        })
      }
    });
  }, [program]);

  const _onFilterPrograms = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string | undefined): void => {
    setFilteredPrograms(text ? programs.filter(i => i.navn.toLowerCase().indexOf(text) > -1) : programs)
  };

  if (isLoading) {
    return <Spinner label='Indlæser...' />
  }

  return (
    <div>
      {program === null ? (
        <TextField
          styles={{ root: { width: 200 } }}
          label="Filtrer på program navn"
          onChange={_onFilterPrograms}
        />
      ) : (
        <div>
          <Link onClick={() => {
            setProgram(null)
            setCourseTypes([]);
            setFilteredPrograms(programs);
          }}>Forside </Link>
          <span> / {program.navn}</span>
        </div>
      )}
      {program === null ?
        (
          <DetailsList
            items={filteredPrograms}
            columns={programColumns}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select row"
          />
        ) : (
          <CourseActionList
            courseTypes={courseTypes}
            onClickDate={setCourseType}
            onClickPrice={setCourseTypePrice}
            onClickDuration={setCourseTypeDuration}

          />
        )}
      {courseType !== null && (
        <DatePanel courseType={courseType} onDismiss={() => setCourseType(null)} />
      )}
      {courseTypePrice !== null && program !== null && (
        <PricePanel courseType={courseTypePrice} program={program} onDismiss={() => setCourseTypePrice(null)} />
      )}
      {courseTypeDuration !== null && program !== null && (
        <DurationPanel courseType={courseTypeDuration} program={program} onDismiss={() => setCourseTypeDuration(null)} />
      )}
    </div>
  );
}

function DatePanel(props: { courseType: ICourseType, onDismiss: () => void; }): JSX.Element {
  const { instance, accounts } = useMsal();
  const [courseDates, setCourseDates] = useState<ICourse[]>([]);
  const [courseDays, setCourseDays] = useState<ICourseDay[]>([]);
  const [courseGroup, setCourseGroup] = useState<{ [key: string]: ICourse[] }>({});
  const [token, setToken] = useState<string>("");

  const [selectedCourseDate, setSelectedCourseDate] = useState<ICourseDay | null>(null);
  const [startDato, setStartDato] = useState<Date>(moment().startOf("day").toDate());
  const [slutDato, setSlutDato] = useState<Date>(moment().startOf("day").toDate());

  const [hideDeleteDialog, setHideDeleteDialog] = useState<boolean>(true);
  const [hideDateDialog, setHideDateDialog] = useState<boolean>(true);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [courseId, setCourseId] = useState<number>(0);

  const _items: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      onClick: () => {
        setSelectedCourseDate(null);
        toggleHideDateDialog();
      },
      text: 'Opret dato',
      iconProps: { iconName: 'Add' },
    },
  ];

  if (selectedCourseDate) {
    _items.push(...[
      {
        key: 'editItem',
        text: 'Ret dato',
        iconProps: { iconName: 'Edit' },
        onClick: () => {
          if (selectedCourseDate.startDato) {
            setStartDato(moment(selectedCourseDate.startDato).startOf("day").toDate());
          }

          if (selectedCourseDate.slutDato) {
            setSlutDato(moment(selectedCourseDate.slutDato).startOf("day").toDate());
          }

          if (selectedCourseDate.kursusID) {
            setCourseId(selectedCourseDate.kursusID);
          }

          toggleHideDateDialog();
        },
      },
      {
        key: 'deleteItem',
        text: 'Slet dato',
        iconProps: { iconName: 'Delete' },
        onClick: () => {
          toggleHideDeleteDialog();
        },
      },
    ])
  }


  useEffect(() => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then((response) => {
      setToken(response.accessToken);
    });
  }, [props])

  useEffect(() => {
    fetchCourseDates();
  }, [token]);

  const fetchCourseDates = async (): Promise<void> => {

    getCourseDates(token, props.courseType.kursustypeID).then(async (data: any) => {
      const test = await getCourseDates(token, props.courseType.kursustypeID);
      const courseDays = await Promise.all(data.data.map(async (course: ICourse) => await getCourseDays(token, course.kursusID)));
      const allDates = courseDays.reduce((acc, currentValue) => acc.concat(currentValue.data), []);
      setCourseDates(allDates);
    })
  }

  const renderDateDialog = () => {

    const dateDialogProps = {
      type: DialogType.normal,
      title: selectedCourseDate ? "Ret dato" : "Opret dato",
      closeButtonAriaLabel: 'Close',
    };

    const dropdownOptions: IDropdownOption[] = [];
    courseDates.forEach(courseDate => {
      if (!dropdownOptions.find(option => option.key === courseDate.kursusID)) {
        dropdownOptions.push({
          key: courseDate.kursusID,
          text: courseDate.kursusID.toString()
        })
      }
    });

    return (
      <Dialog
        hidden={hideDateDialog}
        onDismiss={toggleHideDateDialog}
        dialogContentProps={dateDialogProps}
      >
        {!selectedCourseDate && (
          <Checkbox
            label="Skal datoen tilføjes til et eksisterende kursus?"
            onChange={(_, checked: boolean | undefined) => setChecked(checked ?? false)}
            checked={checked}
          />
        )}
        {checked && !selectedCourseDate && <Dropdown
          label="Kursus ID"
          options={dropdownOptions}
          onChange={(_, option: IDropdownOption | undefined) => setCourseId(Number(option?.key))}
          selectedKey={courseId}
          placeholder="Vælg KursusID"
        />}
        <br />
        <DatePicker
          firstDayOfWeek={DayOfWeek.Monday}
          value={moment(startDato).startOf("day").toDate()}
          formatDate={() =>
            moment(startDato).format("DD-MM-YYYY")
          }
          onSelectDate={(date) => {
            if (date) {
              setStartDato(moment(date).toDate());
              if (selectedCourseDate) {
                setSlutDato(moment(date).toDate());
              }
            }
          }}
          placeholder="Vælg startdato..."
          strings={defaultDatePickerStrings}
        />{!selectedCourseDate &&
          <DatePicker
            firstDayOfWeek={DayOfWeek.Monday}
            value={moment(slutDato).startOf("day").toDate()}
            formatDate={() =>
              moment(slutDato).format("DD-MM-YYYY")
            }
            onSelectDate={(date) => {
              if (date) {
                setSlutDato(moment(date).toDate());
              }
            }}
            placeholder="Vælg slutdato..."
            strings={defaultDatePickerStrings}
          />
        }

        <DialogFooter>
          <PrimaryButton onClick={() => {
            onAddOrUpdate().then(_ => {
              toggleHideDateDialog();
              setChecked(false);
              setCourseId(0);
            })
          }} text={selectedCourseDate ? "Ret" : "Opret"} />
          <DefaultButton onClick={() => {
            setStartDato(moment().startOf("day").toDate());
            setSlutDato(moment().startOf("day").toDate());
            setCourseId(0);
            setChecked(false);
            toggleHideDateDialog();
          }} text="Fortryd" />
        </DialogFooter>
      </Dialog>
    )
  }

  const renderDeleteDialog = () => {

    const deleteDialogProps = {
      type: DialogType.normal,
      title: 'Slet dato',
      closeButtonAriaLabel: 'Close',
      subText: 'Er du sikker på at du vil slette datoen?',
    };

    return (
      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        dialogContentProps={deleteDialogProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={() => {
            onDateDelete().then(_ => {
              toggleHideDeleteDialog();
            });
          }} text="Slet" />
          <DefaultButton onClick={toggleHideDeleteDialog} text="Fortryd" />
        </DialogFooter>
      </Dialog>
    )
  }

  const toggleHideDeleteDialog = (): void => {
    setHideDeleteDialog(!hideDeleteDialog);
  }

  const toggleHideDateDialog = (): void => {
    setHideDateDialog(!hideDateDialog);
  }

  const onDateDelete = async (): Promise<void> => {
    try {
      if (selectedCourseDate) {
        await deleteCourseDate(token, selectedCourseDate.kursusdagID);
      }
    } catch (error) {
      console.log(error);
    } finally {
      await fetchCourseDates();
    }
  }

  const onAddOrUpdate = async (): Promise<void> => {

    var tempId = 1;
    var counter = 0;

    for (let i = startDato; i <= slutDato;) {
      try {
        const newObj: ICourse = {
          kursusID: 0,
          startDato: startDato,
          slutDato: slutDato,
          kursustypeID: props.courseType.kursustypeID,
          antalDeltagere: 0,
          kursusAdresse: 5216,
          kursusLokale: 0,
          kursusTitel: props.courseType.kursusTitel,
          kursustype: "Åbent kursus",
          startDato1Tid: "09001600",
          startDatoTid: "09001600",
        };

        const newDayObj: ICourseDay = {
          kursusdagID: selectedCourseDate?.kursusdagID ?? 0,
          kursusID: courseId == 0 ? tempId : courseId,
          startDato: startDato,
          slutDato: startDato,
        };

        if (selectedCourseDate && counter <= 0) {
          selectedCourseDate.startDato = startDato;
          selectedCourseDate.slutDato = startDato;

        }

        if (newDayObj.kursusID === 1 && !selectedCourseDate) {
          const newCourse: AxiosResponse<ICourse> = await addOrUpdateCourseDate(token, newObj);
          newDayObj.kursusID = newCourse.data.kursusID;
          tempId = newCourse.data.kursusID;
        }

        await addOrUpdateCourseDay(token, newDayObj);
        i = new Date(startDato.setDate(startDato.getDate() + 1));
        counter++;

      } catch (error) {
        console.log(error);
      } finally {
        await fetchCourseDates();
      }
    }

  }


  useEffect(() => {
    let tempCourseGroup: { [key: string]: ICourse[] } = {};
    courseDates.forEach((item) => {
      if (!(item.kursusID in tempCourseGroup)) {
        tempCourseGroup[item.kursusID] = [item];
      } else {
        tempCourseGroup[item.kursusID].push(item);
      }
    });

    setCourseGroup(tempCourseGroup);
  }, [courseDates]);

  return (
    <Panel
      headerText={props?.courseType.kursusTitel}
      type={PanelType.medium}
      isOpen={props !== null}
      onDismiss={() => props.onDismiss()}
      // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
      closeButtonAriaLabel="Close"
    >
      <>
      </>
      <p>Administrer datoer</p>
      <div style={{ height: 50, marginLeft: "-24px" }}>
        <CommandBar
          items={_items}
          ariaLabel="Inbox actions"
          primaryGroupAriaLabel="Email actions"
          farItemsGroupAriaLabel="More actions"
        />
      </div>

      {Object.keys(courseGroup).map(key => {
        const dateColumns = ([
          {
            name: "Start dato", key: "startDato", maxWidth: 100, minWidth: 50, fieldName: "startDato",
            onRender: (item: any) => {
              return <span>{new Date(item.startDato).toLocaleDateString("da-DK", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              }).replaceAll(".", "-")}</span>
            }
          },
          {
            name: "Slut dato", key: "slutDato", fieldName: "slutDato", maxWidth: 250, minWidth: 50, onRender: (item: any) => {
              const date = item.slutDato;
              return <span>{new Date(date).toLocaleDateString("da-DK", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              }).replaceAll(".", "-")}</span>
            },
          },
        ]);

        return (
          <div style={{ margin: 20 }}>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Label style={{ marginRight: '1.5rem' }}>{key}</Label>
            </div>

            <div>
              <CourseDetailList
                items={courseGroup[key]}
                setSelectedCourseDate={(item) => setSelectedCourseDate(item)}

              />
            </div>
          </div>
        )
      })}


      {renderDateDialog()}
      {renderDeleteDialog()}

    </Panel>
  )
}
