import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Button } from "react-bootstrap";
import { ICourseRegistration } from "./RegistrationsTable";
import { deleteCourseRegistration, deletePeoplenetCourseRegistration } from "../api";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import "../styles/DeleteRegistration.css";
import { blueGrey } from "@mui/material/colors";
import moment from "moment";

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}



export function DeleteRegistrationDialog(props: { open: boolean; onClose: () => void; refresh: (state: boolean) => void; refreshState: boolean; registration: ICourseRegistration | undefined; token: string; }): JSX.Element {
    const [status, setStatus] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getSECourseEmail = (emailBody: string) => {

        if (emailBody.indexOf('Email: ') > -1) {
            return emailBody.substring(emailBody.indexOf('Email: ') + 7, emailBody.indexOf('<br />Afdeling:'));
        }
    }
    const getSECourseName = (emailBody: string) => {

        if (emailBody.indexOf('Kursus: ') > -1) {
            return emailBody.substring(emailBody.indexOf('Kursus: ') + 7, emailBody.indexOf('<br />Kursus Id:'));
        } else {
            return emailBody.substring(emailBody.indexOf('Test: '), emailBody.indexOf('<br />Test Id:'));
        }

    }
    const handleDeleteRegistration = () => {
        if (props.registration && props.registration.seid) {

            if (props.registration.firma.toLowerCase() == "4D".toLowerCase()) {
                deleteCourseRegistration(props.token, props.registration.seid);
            } else if (props.registration.firma.toLowerCase() == "PeopleNet".toLocaleLowerCase()) {
                deletePeoplenetCourseRegistration({ "id": props.registration.seid });
            }

            props.onClose();
            props.refresh(!props.refreshState);
        } else {
            setError("Der var et problem med at finde sagsbehandler. Kontakt ndh@peoplenet.dk")
        }



    }

    const handeConfirmAction = (status: boolean) => {
        setStatus(status)
    }

    const sanitizeHtml = (html: string) => {
        const htmlString = props.registration?.seBody;
        if (htmlString) {
            // const toRemove = htmlString.substring(htmlString.indexOf('<br />Klippekort nr: '), htmlString.indexOf('<br />Modtag nyheder: '));
            const toRemove2 = htmlString.substring(htmlString.indexOf('<br />Kundetype:'), htmlString.indexOf('<br />Firmanavn:'));
            const toRemove3 = htmlString.substring(htmlString.indexOf('<br />Starttid:'), htmlString.indexOf('<br />Oplyst pris:'));
            const toRemove4 = htmlString.substring(htmlString.indexOf('<br />Adresse:'), htmlString.indexOf('<br />Telefon:'));
            const toRemove5 = htmlString.substring(htmlString.indexOf('<br />CVR nr.::'), htmlString.indexOf('<br />Kursus:'));
            var sanitizedString = htmlString.replace(toRemove2, '');
            sanitizedString = sanitizedString.replace(toRemove3, '')
            sanitizedString = sanitizedString.replace(toRemove4, '')
            sanitizedString = sanitizedString.replace(toRemove5, '')
            return sanitizedString;
        }else{
            return "";
        }

    }

    return (
        <Dialog onClose={props.onClose} open={props.open} >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Slet tilmelding</DialogTitle>
            <IconButton
                aria-label="trash"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: blueGrey,
                }}
            >
                <CloseIcon />
            </IconButton>
            {error && (
                <div>
                    {error}
                </div>
            )}

            {status ? (
                <div>
                    <DialogContent className='dialogContainer'>
                        <div className="warning">Denne tilmelding vil blive slettet permanent</div>
                        {props.registration?.firma.toLowerCase() == "PeopleNet".toLowerCase() ? (

                            <div className='modalBody'>

                                <div>Firmanavn: {props.registration.company}</div>
                                <div>Adresse: {props.registration.address}</div>
                                <br />
                                <div>Deltager navn: {props.registration.firstname + " " + props.registration.lastname}</div>
                                <div>Email: {props.registration.zip ? props.registration.zip : props.registration.email}</div>
                                <div>Mobil nr: {props.registration.phone}</div>
                                <br />
                                <div>Kursus: {props.registration.title}</div>
                                <div>Kursus sted: {props.registration.courseLocation}</div>
                                <div>Kursus dag id: {props.registration.coursedateId}</div>
                                <div>Kursus dag: {moment(props.registration.coursedate).format("DD-MM-YYYY")}</div>
                                <div>Pris: {props.registration.price} Kr.</div>
                                <br />
                                <div>Kommentar: {props.registration.comments}</div>
                            </div>


                        ) : (
                            <div className='modalBody' dangerouslySetInnerHTML={{ __html: props.registration?.seBody ? sanitizeHtml(props.registration.seBody) : ""}}></div>
                        )}


                    </DialogContent>



                    <DialogActions>

                        <Button onClick={props.onClose} variant="secondary">
                            Afbryd og luk
                        </Button>
                        <Button onClick={() => handleDeleteRegistration()} variant="danger">
                            Slet og luk
                        </Button>


                    </DialogActions>
                </div>
            ) : (
                <div>
                    <DialogContent className='dialogContainer'>
                        <div className="warning">Du er ved at slette tilmelding:</div>
                        {props.registration?.firma.toLowerCase() == "PeopleNet".toLowerCase() ? (
                            <div>

                                <div className='modalBody'>

                                    <div>Firmanavn: {props.registration.company}</div>
                                    <div>Adresse: {props.registration.address}</div>
                                    <br />
                                    <div>Deltager navn: {props.registration.firstname + " " + props.registration.lastname}</div>
                                    <div>Email: {props.registration.zip ? props.registration.zip : props.registration.email}</div>
                                    <div>Mobil nr: {props.registration.phone}</div>
                                    <br />
                                    <div>Kursus: {props.registration.title}</div>
                                    <div>Kursus sted: {props.registration.courseLocation}</div>
                                    <div>Kursus dag id: {props.registration.coursedateId}</div>
                                    <div>Kursus dag: {moment(props.registration.coursedate).format("DD-MM-YYYY")}</div>
                                    <div>Pris: {props.registration.price} Kr.</div>
                                    <br />
                                    <div>Kommentar: {props.registration.comments}</div>
                                </div>

                            </div>

                        ) : (
                            <div>
                                <div className='modalBody' dangerouslySetInnerHTML={{__html: props.registration?.seBody ? sanitizeHtml(props.registration.seBody) : ""}}></div>
                            </div>

                        )}
                        <div className="warning">Er du sikker på at du vil slette denne tilmelding?</div>

                    </DialogContent>



                    <DialogActions>

                        <Button onClick={props.onClose} variant="secondary">
                            Afbryd og luk
                        </Button>
                        <Button onClick={() => handeConfirmAction(true)} variant="danger">
                            Gå videre til sletning
                        </Button>


                    </DialogActions>
                </div>

            )}

        </Dialog>
    )
}