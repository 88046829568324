

export default function PriceDisplay(props: { fourDprice: number, peoplenetPrice: number, formatPrice: (price: number) => void; }): JSX.Element {

  return (
    <div className='totalPriceContainer'>
      <div className='priceContainer'>
        <div className='text'>Total pris 4D:</div>
        {/* @ts-ignore */}
        <div >{props.formatPrice(props.fourDprice)},- kr</div>
      </div>
      <div className='priceContainer'>
        <div className='text'>Total pris PeopleNet:</div>
        {/* @ts-ignore */}
        <div>{props.formatPrice(props.peoplenetPrice)},- kr</div>
      </div>

    </div>
  )
}