import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { DetailsList, DetailsListLayoutMode, SelectionMode, Selection } from "@fluentui/react";
import { ICourse, ICourseDay } from "./DataTable";


export interface ICourseProps {
    items: ICourse[];
    setSelectedCourseDate: (item: ICourseDay) => void;
}

export default function CourseDetailList(props: ICourseProps) {

    const dateColumns = ([
        {
          name: "Start dato", key: "startDato", maxWidth: 100, minWidth: 50, fieldName: "startDato",
          onRender: (item: any) => {
            return <span>{new Date(item.startDato).toLocaleDateString("da-DK", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit"
            }).replaceAll(".", "-")}</span>
          }
        },
        {
          name: "Slut dato", key: "slutDato", fieldName: "slutDato", maxWidth: 250, minWidth: 50, onRender: (item: any) => {
            const date = item.slutDato;
            return <span>{new Date(date).toLocaleDateString("da-DK", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit"
            }).replaceAll(".", "-")}</span>
          },
        },
      ]);

      const [_selection] = useState(new Selection({
        onSelectionChanged: () => {
          const [selectedDate]: any[] = _selection.getSelection();
          props.setSelectedCourseDate(selectedDate);
        }
      }));
    return (
        <DetailsList
            items={props.items}
            columns={dateColumns}
            selection={_selection}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select row"
        />
    );


}
