import { Label, Panel, PanelType, PrimaryButton } from "@fluentui/react";
import Alert from '@mui/material/Alert';
import { ICourseProgram, ICourseType, ICourseTypePrice } from "./DataTable";
import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { getCourseTypes, updateCoursePrice } from "../api";
import "../styles/PricePanel.css";

export default function PricePanel(props: { courseType: ICourseType, program: ICourseProgram, onDismiss: () => void; }): JSX.Element {
    const [courseTypes, setCourseTypes] = useState<ICourseTypePrice | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const inputPrice = useRef<HTMLInputElement>(null);
    const inputDuration = useRef<HTMLInputElement>(null);
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            setToken(response.accessToken);
        });
    }, [props])

    useEffect(() => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            if (props.program) {
                getCourseTypes(response.accessToken, props.program?.programnavnID).then((data: any) => {
                    const type = data.data.find((type: any) => type.kursusTitel === props.courseType.kursusTitel);
                    setCourseTypes(type);
                    setIsLoading(false);
                })
            }
        });
    }, []);


    const onUpdatePrice = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (inputPrice.current?.value !== "" && inputPrice.current?.value !== undefined) {

            try {
                if (courseTypes) {
                    const newTypeObj: ICourseTypePrice = {
                        kursusTitel: props.courseType.kursusTitel,
                        kursustypeID: props.courseType.kursustypeID,
                        pris: parseFloat(inputPrice.current?.value),
                        programnavnID: props.program.programnavnID,
                        varighed: courseTypes.varighed,
                    }
          
                    await updateCoursePrice(token, newTypeObj);
                    setCourseTypes(newTypeObj);
                }

            } catch (error) {
                console.log(error);
            }
        } else {
            setError("Input feltet er tom");
        }


    }

    return (
        <Panel
            headerText={props?.courseType.kursusTitel}
            type={PanelType.smallFixedFar}
            isOpen={props !== null}
            onDismiss={() => props.onDismiss()}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel="Close"
            className="pricePanel"
        >
            <p>Administrer priser</p>

            {!isLoading && courseTypes !== null && (
                <div className="container">
                    <div className="priceInfoContainer">
                        <Label htmlFor="priceInfo">Pris pr. dag:</Label>
                        {courseTypes.pris === null ? (
                            <span className="price" id="priceInfo">0 kr.</span>
                        ) : (
                            <span className="price" id="priceInfo">{courseTypes.pris} kr.</span>
                        )}

                    </div>

                    <form>
                        <Label htmlFor='newPrice'>Ny pris</Label>
                        <div className="inputContainer">
                            <input ref={inputPrice} type='number' id="newPrice" onClick={ev => setError("")} />
                            <PrimaryButton className="btnSave" onClick={onUpdatePrice}>Gem</PrimaryButton>
                        </div>

                    </form>
                    {error != "" && (
                        <Alert severity="warning">{error}</Alert>
                    )}

                </div>

            )}


        </Panel>
    )
}
