import axios, { AxiosResponse } from 'axios';
import { ICourse , ICourseDay, ICourseTypePrice} from './components/DataTable';
import { ICourseRegistration } from './components/RegistrationsTable';

const url = window.location.href.indexOf("localhost") !== -1 ? "https://localhost:7276/" : "https://4dkursusinterface.azurewebsites.net/"

export async function getPrograms(accessToken: string) {
    return axios.get(url, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}
export async function getTypes(accessToken: string) {
    return axios.get(url + "types", {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function getCourseTypes(accessToken: string, id: number) {
    return axios.get(url + "types/" + id.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function getCourseDates(accessToken: string, id: number) {
    return axios.get(url + id.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}
export async function getCourseDays(accessToken: string, courseId: number) {
    return axios.get(url + "days/" + courseId.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function getAllCourseRegistrations(accessToken: string) {
    return axios.get(url + "registration", {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function addOrUpdateCourseDate(accessToken: string, course: ICourse) {
    return axios.post(url, course, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    });
}
export async function addOrUpdateCourseDay(accessToken: string, day: ICourseDay) {
    return axios.post(url + "day", day, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}
export async function updateCoursePrice(accessToken: string, type: ICourseTypePrice) {
    return axios.post(url + "type", type, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}
export async function updateRegistrationStatus(accessToken: string, registration: any) {

    return axios.post(url + "registration", registration, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}

export async function deleteCourseDate(accessToken: string, id: number) {
    return axios.delete(url + id.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}
export async function deleteCourseRegistration(accessToken: string, id: number) {

    return axios.delete(url + "Kurser/registration/" +id.toString(), {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
        .catch((error: any) => console.log(error));
}
export async function deletePeoplenetCourseRegistration(data: any) {
    
    return axios.put('https://peoplenet.dk/wp-json/pncourses/v1/delete-submission',  data)
    .catch((error: any) => console.log("Der skete et problem med at slette en tilmelding i Peoplenet endpoint. Error: " + error));
}

export async function getAllPeoplenetCourseRegistrations() {
    return axios.get('https://peoplenet.dk/wp-json/pncourses/v1/get-submissions')
        .catch((error: any) => console.log("Der skete et problem med at hente tilmeldinger fra Peoplenet endpoint. Error: " + error));
}
export async function getAllPeoplenetCoursePrices() {
    return axios.get('https://peoplenet.dk/wp-json/pncourses/v1/get-course-prices')
        .catch((error: any) => console.log("Der skete et problem med at hente kursus priser fra Peoplenet endpoint. Error: " + error));
}

export async function updatePeoplenetRegistrationStatus( data: any) {
    return axios.put('https://peoplenet.dk/wp-json/pncourses/v1/post-submission', data)
        .catch((error: any) => console.log("Der skete et problem med at poste en tilmelding til Peoplenet endpoint. Error: " + error));
}